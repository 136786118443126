var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "안전작업표준 작업명",
                      value: _vm.param.sopName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "사업장", value: _vm.param.plantName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "단위공정", value: _vm.param.processName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "안전작업표준 관리번호",
                      value: _vm.param.sopNo,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "작업구분",
                      value: _vm.param.workClassName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "작업유형", value: _vm.param.workTypeName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "작업형태", value: _vm.param.workKindName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "위험등급",
                      value: _vm.param.hazardGradeName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "제(개)정번호",
                      value: _vm.param.revisionNum,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "제(개)정자",
                      value: _vm.param.regUserName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "제(개)정일시", value: _vm.param.regDtStr },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-table", {
          ref: "table",
          attrs: {
            title: "작업절차",
            tableId: "steptable",
            topBorderClass: "topcolor-lightblue",
            columnSetting: false,
            usePaging: false,
            filtering: false,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }